// @flow

import React from "react";

import { Footer as StyledFooter } from "./styles";
import { conversationTypes } from "src/constants/display";
import WorkflowName from "src/containers/workflow/Name";

type Props = {
  text: string,
  type: string,
  workflow: ?number,
  handleCreate: Function,
  handleNewRoom: Function
};

const Footer = ({
  text,
  type,
  workflow,
  handleCreate,
  handleNewRoom
}: Props) => {
  if (text) {
    return (
      <StyledFooter
        tabIndex="0"
        type="button"
        onClick={() => handleCreate(text)}
      >
        &#43; Create{"   "}
        <strong>{text}</strong>
      </StyledFooter>
    );
  }

  if (type === "workflow" && workflow) {
    return (
      <StyledFooter type="button" onClick={handleNewRoom}>
        &#43; Create New <WorkflowName id={workflow} />
      </StyledFooter>
    );
  }

  return (
    <StyledFooter tabIndex="0" onClick={handleNewRoom}>
      &#43; Create New {conversationTypes[type][0]}
    </StyledFooter>
  );
};

export default Footer;
