// @flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import type { FieldId } from "src/types";
import {
  getFirstChecklist,
  getChecklistFieldsBySections,
  getChecklistFieldsById
} from "src/reducers";
import {
  getFilteredFields,
  getMetaDataInitialState,
  metaDataLabels,
  getChecklistFieldsToCopyInitialState
} from "./utils";
import { pickTruthyKeys } from "src/utils";

import {
  Text,
  Button,
  Modal as ChakraModal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  HStack,
  VStack,
  Checkbox,
  Textarea
} from "@chakra-ui/react";
import ChecklistFieldsSelectionModal from "./ChecklistFieldsSelectionModal";
import * as styles from "./styles";

type Props = {
  open: boolean,
  onClose: Function,
  onSuccess: Function,
  defaultCopyableFields: FieldId[]
};

function Modal({ open, onClose, onSuccess, defaultCopyableFields }: Props) {
  const [selectFieldsToCopy, setSelectFieldsToCopy] = useState(false);
  const [comment, setComment] = useState("");
  const [metaData, setMetaData] = useState(getMetaDataInitialState());
  const [copyChecklistData, setCopyChecklistData] = useState(true);

  const { fieldsBySection, allFieldsById } = useSelector(({ app }) => {
    const { id: checklistId } = getFirstChecklist(app);
    const fieldsBySection = getChecklistFieldsBySections(app).get(
      `${checklistId}`
    );
    const allFieldsById = getChecklistFieldsById(app);

    return { fieldsBySection, allFieldsById };
  });

  const filteredFields = getFilteredFields(fieldsBySection, allFieldsById);

  const [checklistFieldsToCopy, setChecklistFieldsToCopy] = useState(
    getChecklistFieldsToCopyInitialState(
      filteredFields,
      defaultCopyableFields,
      allFieldsById
    )
  );

  const handleMetaDataChange = e => {
    setMetaData(prev => ({ ...prev, [e.target.id]: e.target.checked }));
  };

  const handleChecklistFieldsToCopyChange = e => {
    setChecklistFieldsToCopy(prev => ({
      ...prev,
      [e.target.id]: e.target.checked
    }));
  };

  const createRevision = () => {
    onSuccess({
      comment,
      clone: {
        metadata: pickTruthyKeys(metaData),
        checklistFields: pickTruthyKeys(checklistFieldsToCopy).map(fieldId =>
          Number(fieldId)
        )
      }
    });
    onClose();
  };

  useEffect(() => {
    setChecklistFieldsToCopy(prev => {
      const newFields = {};
      Object.keys(prev).forEach(fieldId => {
        newFields[fieldId] = defaultCopyableFields.includes(Number(fieldId))
          ? copyChecklistData
          : false;
      });
      return newFields;
    });
  }, [copyChecklistData]);

  if (selectFieldsToCopy) {
    return (
      <ChecklistFieldsSelectionModal
        checklistFields={allFieldsById.toJS()}
        fieldsToCopy={checklistFieldsToCopy}
        onFieldSelect={handleChecklistFieldsToCopyChange}
        open
        onClose={onClose}
        onSuccess={createRevision}
        onBack={() => setSelectFieldsToCopy(false)}
      />
    );
  }

  return (
    <ChakraModal
      isOpen={open}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent {...styles.modalContent}>
        <ModalHeader {...styles.modalHeader}>Create new revision</ModalHeader>

        <ModalBody {...styles.modalBody}>
          <VStack {...styles.metadataList}>
            <Text {...styles.label1}>
              Select the data to copy to new revision
            </Text>
            {Object.keys(metaData).map(key => (
              <Checkbox
                isChecked={metaData[key]}
                key={key}
                id={key}
                onChange={handleMetaDataChange}
              >
                {metaDataLabels[key]}
              </Checkbox>
            ))}
            <HStack justifyContent="space-between" width="100%">
              <Checkbox
                isChecked={copyChecklistData}
                onChange={({ target }) => setCopyChecklistData(target.checked)}
              >
                Checklist and form data
              </Checkbox>
              <Button
                {...styles.link}
                onClick={() => setSelectFieldsToCopy(true)}
              >
                Select fields
              </Button>
            </HStack>
          </VStack>

          <VStack {...styles.commentContainer}>
            <Text {...styles.label1}>Revision notes</Text>
            <Text {...styles.label2}>Why are you making this revision?</Text>
            <Textarea
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Enter optional comment here."
              {...styles.textarea}
            />
          </VStack>
        </ModalBody>

        <HStack {...styles.modalFooter}>
          <Button variant="uBlueFilled" onClick={createRevision}>
            Create revision
          </Button>
          <Button variant="uSecondary" onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
