// @flow

import React from "react";

import { Selection as StyledSelection } from "./styles";

type Props = {
  value: ?string
};

const Selection = ({ value }: Props) => (
  <StyledSelection>{value || "All Conversations"}</StyledSelection>
);

export default Selection;
