// @flow

import { connect } from "react-redux";

import Input from "src/components/chatroom/Input/Chat";
import { searchUsers } from "src/actions";
import {
  insertChat,
  setConversationAttributes,
  focusChatInput,
  addRoomMember,
  setNewWorkflow,
  deleteTyping,
  setTyping
} from "src/actions/chatroom";
import { setComponent, clearReplyTo } from "src/actions/chatmenu";
import { triggerClearInput, resetClearInput } from "src/actions/input";
import {
  getChatRoomIsAddFile,
  getCurrentUserId,
  getChatroomType
} from "src/reducers";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  isAddFile: getChatRoomIsAddFile(app),
  currentUser: getCurrentUserId(app),
  type: getChatroomType(app, props.roomId),
  isSrw: app.srw.isSingleResponse
});

export default connect(mapStateToProps, {
  setMenuComponent: setComponent,
  clearReply: clearReplyTo,
  insertMssg: insertChat,
  setAttributes: setConversationAttributes,
  searchUsers,
  focusChatInput,
  setNewWorkflow,
  addRoomMember,
  triggerClearInput,
  resetClearInput,
  deleteTyping,
  setTyping
})(Input);
