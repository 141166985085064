// @flow

import "firebase/messaging";

import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { vapidKey } from "src/config/firebase";
import enableNotifications from "src/api/notifications";

import { EnableNotificationMessage } from "src/styles";

type Props = {
  orgRole: string,
  notificationChanged: Function,
  isNotificationEnabled: boolean,
  dispatch: Function
};

type State = {
  hide: boolean
};

class PushNotificationStatus extends PureComponent<Props, State> {
  requestPermission: Function;
  hidePrompt: Function;
  hasUserEnabledNotification: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      hide: false
    };
    this.hasUserEnabledNotification = this.hasUserEnabledNotification.bind(
      this
    );
    this.hidePrompt = this.hidePrompt.bind(this);
  }

  componentDidMount() {
    this.hasUserEnabledNotification();

    const statusBar = localStorage.getItem("hidePushNotification");
    const hidePushNotification = statusBar ? JSON.parse(statusBar) : null;

    if (hidePushNotification) {
      this.setState({ hide: true });
    }
  }

  hidePrompt() {
    localStorage.setItem("hidePushNotification", "true");
    this.setState({ hide: true });
  }

  async hasUserEnabledNotification() {
    const { notificationChanged, dispatch } = this.props;
    try {
      notificationChanged(true);
      // If user has allowed notification
      // then get notification token and handle refresh token
      enableNotifications(vapidKey, dispatch);
      // $FlowFixMe
    } catch ({ code, message }) {
      // Notification is enabled, change state
      if (code === "messaging/permission-blocked") {
        notificationChanged(false);
      } else {
        // eslint-disable-next-line
        console.log("Unknown error in notifications - ", code, message);
      }
    }
  }

  enableNotification = () => {
    Promise.resolve(window.Notification.requestPermission()).then(result => {
      if (result === "denied") {
        this.hidePrompt();
      }
      if (result === "default") {
        // the user has closed the dialog
        if (window.Notification.permission === "denied") {
          // the browser has decided to automatically denied permission
          this.hidePrompt();
        }
      }
      this.hidePrompt();
      // the user has granted permission
    });
  };

  render() {
    window.Notification.requestPermission();
    const { orgRole, isNotificationEnabled } = this.props;
    const { hide } = this.state;

    if (orgRole === "demo") {
      return <></>;
    }

    return isNotificationEnabled || hide ? null : (
      <EnableNotificationMessage>
        <div>
          Unifize works best with notifications -{" "}
          <button type="button" onClick={this.enableNotification}>
            Click here to enable
          </button>
        </div>
        <button
          type="button"
          onClick={this.hidePrompt}
          onKeyPress={this.hidePrompt}
        >
          X
        </button>
      </EnableNotificationMessage>
    );
  }
}

export default connect(null, dispatch => ({ dispatch }))(
  PushNotificationStatus
);
