// @flow

import { connect } from "react-redux";
import React from "react";

import ChatInput from "src/components/chatroom/Input";
import { getCurrentRoomId } from "src/reducers";

import type { RoomId, AppState } from "src/types";

type Props = {
  roomId: RoomId
};

const CurrentChatInput = ({ roomId }: Props) => <ChatInput roomId={roomId} />;

const mapStateToProps = ({ app }: { app: AppState }) => ({
  roomId: getCurrentRoomId(app)
});

export default connect(mapStateToProps)(CurrentChatInput);
