// @flow

import React from "react";
import RouterLink from "redux-first-router-link";
import { connect } from "react-redux";

import { getChatroomAddress, getTemplateId } from "src/reducers";
import * as atypes from "src/constants/actionTypes";
import ChatRoomName from "src/containers/chatroom/Name";

import type { RoomId, ChatroomAddress, AppState } from "src/types";

type Props = {
  nolink: boolean,
  address: ChatroomAddress,
  id: RoomId,
  templateId: ?number
};

const BreadCrumb = ({ address, id, nolink, templateId }: Props) => {
  if (nolink) {
    return (
      <>
        <ChatRoomName id={id} noTitle={false} />
        <span>&gt;</span>
      </>
    );
  }

  return (
    <RouterLink
      key={id}
      title={id}
      to={{
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: { id: address },
        meta: {
          query: {
            templateId
          }
        }
      }}
    >
      <ChatRoomName id={id} noTitle={false} isBreadcrumb />
      <span>&gt;</span>
    </RouterLink>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { id }: { id: RoomId }
) => ({
  address: getChatroomAddress(app, id),
  templateId: getTemplateId(app, id)
});

export default connect(mapStateToProps)(BreadCrumb);
