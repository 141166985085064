// @flow

import firebase from "firebase/app";

import "firebase/messaging";
import "firebase/firestore";

import { auth } from "src/api";

import type { UID } from "src/types";
import * as atypes from "src/constants/actionTypes";

const updateNotificationToken = async ({
  uid,
  token
}: {
  uid: UID,
  token: string
}) => {
  const docRef = await firebase
    .firestore()
    .collection(`userData/${uid}/appData`)
    .doc("notification");
  const doc = await docRef.get();
  if (doc.exists) {
    await docRef.update({
      tokens: firebase.firestore.FieldValue.arrayUnion(token)
    });
  } else {
    await docRef.set({ tokens: [token] });
  }
};

const enableNotifications = async (vapidKey: string, dispatch: Function) => {
  if ("serviceWorker" in navigator) {
    const messaging = firebase.messaging();
    // $FlowFixMe
    const registration = await navigator.serviceWorker.getRegistration("/");
    await messaging.requestPermission({});

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", (e: any) => {
        const { data } = e;
        if (data.action && data.action === "SWITCH_CHATROOM") {
          const { address: id } = data;
          dispatch({
            type: atypes.SET_CURRENT_CHATROOM_REQUEST,
            payload: { id }
          });
        }
      });
    }

    try {
      await messaging.requestPermission({
        serviceWorkerRegistration: registration,
        vapidKey
      });

      const currentToken = await messaging.getToken();
      const currentUser = await auth.getCurrentUser();

      await updateNotificationToken({
        uid: currentUser.uid,
        token: currentToken
      });

      messaging.onTokenRefresh(async () => {
        const newToken = await messaging.getToken();

        await updateNotificationToken({
          uid: currentUser.uid,
          token: newToken
        });
      });
      // $FlowFixMe
    } catch ({ code, message }) {
      // Notification is enabled, change state
      // eslint-disable-next-line
      // console.log("Unknown error in notifications - ", code, message);
    }

    messaging.onMessage(payload => {
      console.log("on message handler triggered", payload);
      const { data } = payload;
      const { title, body, roomAddress } = data;
      if (document.hasFocus()) {
        const currentRoomAddress = window.location.href.split("/").slice(-1)[0];
        if (roomAddress !== currentRoomAddress) {
          // $FlowFixMe
          return registration.showNotification(title, { body, data });
        }
        return null;
      }
      // $FlowFixMe
      return registration.showNotification(title, { body, data });
    });
  }
};

export default enableNotifications;
