// @flow

import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Item from "./Item";
import { getDueDate } from "src/reducers";
import type { AppState } from "src/types";
import { getDueDate as getDate } from "src/utils";

type Props = {
  dueDate: ?string,
  statusTrigger: Function
};

const DueDate = ({ dueDate, statusTrigger }: Props) => {
  const value: string | Object = getDate(dueDate);
  return (
    <Item
      label="Due Date"
      icon="dueDate"
      value={value ? moment(value).format("MMM DD, YYYY") : "Not Assigned"}
      handleClick={statusTrigger}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  dueDate: getDueDate(app, props.roomId)
});

export default connect(mapStateToProps)(DueDate);
