// @flow

import { connect } from "react-redux";

import { getWorkflowTemplates } from "src/reducers";
import Select from "src/components/conversation-types/Select";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  workflows: getWorkflowTemplates(app)
});

export default connect(mapStateToProps)(Select);
