// @flow
import React from "react";
import { connect } from "react-redux";
import {
  getUserAndGroupMentions,
  getChatroomType,
  getDirectMessageMentions,
  getRoomMembers
} from "src/reducers";
import type {
  AppState,
  RoomId,
  UID,
  MessageText,
  ChatInputFocus
} from "src/types";
import TextInputWithMentions from "./TextInputWithMentions";

type Props = {
  clearInput: boolean,
  roomId: RoomId,
  users: Array<Object>,
  mentionSuggestionId?: string,
  users: Array<UID>,
  handleInput: Function,
  input: string,
  handleKeyDown: Function,
  handleSendClick: Function,
  chatInputFocus: ChatInputFocus,
  currentMessage: MessageText
};

const TextInput = ({
  roomId,
  users,
  handleInput,
  handleSendClick,
  currentMessage,
  chatInputFocus
}: Props) => {
  return (
    <TextInputWithMentions
      users={users}
      handleInput={handleInput}
      handleSendClick={handleSendClick}
      roomId={roomId}
      currentMessage={currentMessage}
      chatInputFocus={chatInputFocus}
    />
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const conversationType = getChatroomType(app, props.roomId);
  const members = getRoomMembers(app);

  return {
    users:
      conversationType === "direct"
        ? getDirectMessageMentions(app, members)
        : getUserAndGroupMentions(app),
    chatInputFocus: app.chatRooms.focusChatInput,
    clearInput: app.recentChats.clearInput
  };
};

TextInput.defaultProps = {
  mentionSuggestionId: "mention-suggestions"
};

export default connect(mapStateToProps, {})(TextInput);
