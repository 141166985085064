// @flow

import { connect } from "react-redux";
import React from "react";
import moment from "moment";

import { Dock as StyledDock, Content } from "../styles";
import Info from "../Info";
import { Section, SubHeader, Value } from "./styles";
import User from "src/containers/user/NameOrEmail";
import { getCreator, getCreatedDate } from "src/reducers";

import type { AppState, UID } from "src/types";

type Props = {
  creator: UID,
  createdAt: string,
  activeDock: boolean,
  isSrw: boolean
};

const Details = ({ creator, createdAt, activeDock, isSrw }: Props) => {
  return (
    <StyledDock open={activeDock}>
      <Info title="Details" back isSrw={isSrw} />
      <Content>
        <Section>
          <SubHeader>Creator</SubHeader>
          <Value>{creator && <User uid={creator} />}</Value>
        </Section>

        <Section>
          <SubHeader>Date Created</SubHeader>
          <Value>
            {createdAt
              ? moment(createdAt).format("MMM DD, YYYY hh:mm a")
              : null}
          </Value>
        </Section>

        <Section>
          <SubHeader>Age</SubHeader>
          <Value>
            {createdAt ? (
              <>{moment().diff(moment(createdAt), "days")} Days</>
            ) : null}
          </Value>
        </Section>
      </Content>
    </StyledDock>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  creator: getCreator(app, `${props.room}`),
  createdAt: getCreatedDate(app, `${props.room}`)
});

export default connect(mapStateToProps)(Details);
