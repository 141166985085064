// @flow

import React, { Component, Fragment } from "react";
import * as R from "ramda";

import Result from "./result";
import Selection from "./Selection";
import { Select as StyledSelect } from "./styles";
import selector from "src/components/selector";
import { TextWithputBorders as SearchInput } from "src/styles/input";
import Icon from "src/components/Icon";

import type { Workflow } from "src/types";

type Props = {
  dropdown: boolean,
  autoFocus?: boolean,
  value: ?string,
  focus: number,
  excludeSelected?: boolean,
  workflows: Array<Workflow>,
  defaultTypes: Array<Object>,
  handleClose: Function,
  handleChange: Function,
  handleDropdown: Function
};

type State = {
  search: string,
  result: Array<{
    id: number,
    title: string,
    invocationCount: ?number
  }>
};

class Select extends Component<Props, State> {
  outerRef: any;
  searchRef: any;

  static defaultProps = {
    autoFocus: false,
    excludeSelected: false
  };

  state = {
    search: "",
    result: []
  };

  componentDidMount() {
    const { defaultTypes, workflows } = this.props;
    if (workflows) {
      this.setState({
        result: [
          ...defaultTypes,
          ...workflows.map(x => ({
            id: x.id,
            title: x.title,
            invocationCount: x.invocationCount
          }))
        ]
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { defaultTypes, workflows } = this.props;

    if (prevProps.workflows.length !== workflows.length) {
      if (workflows) {
        this.setState({
          result: [
            ...defaultTypes,
            ...workflows.map(x => ({
              id: x.id,
              title: x.title,
              invocationCount: x.invocationCount
            }))
          ]
        });
      }
    }
  }

  handleSearch = (e: any) => {
    const { value } = e.target;
    const { defaultTypes, workflows } = this.props;

    const result = R.filter(
      c => R.includes(R.toLower(value), R.toLower(c.title)),
      [
        ...defaultTypes,
        ...workflows.map(x => ({
          id: x.id,
          title: x.title,
          invocationCount: x.invocationCount
        }))
      ]
    );
    this.setState({ search: value, result });
    this.handleDropdown();
  };

  onKeyDown = (e: any) => {
    if (e) {
      // On Enter selecting the first user in the search result
      if (e.key === "Enter") {
        const { focus } = this.props;
        const { result } = this.state;
        const value = result[focus];
        if (value) {
          this.handleSelect(value);
          e.preventDefault();
        }
      }
    }
  };

  handleSelect = (item: ?Object) => {
    const { defaultTypes, workflows, handleChange, handleClose } = this.props;

    this.setState({
      search: "",
      result: [
        ...defaultTypes,
        ...workflows.map(x => ({
          id: x.id,
          title: x.title,
          invocationCount: x.invocationCount
        }))
      ]
    });
    handleClose();
    handleChange(item);
  };

  removeHandler = (e: any) => {
    e.stopPropagation();
    this.handleSelect(null);
    if (this.searchRef) {
      this.searchRef.focus();
    }
  };

  handleDropdown = () => {
    const { dropdown, handleDropdown } = this.props;
    if (!dropdown) {
      if (this.searchRef) {
        this.searchRef.focus();
      }
      handleDropdown();
    }
  };

  render() {
    const {
      value,
      dropdown,
      autoFocus,
      excludeSelected,
      focus,
      handleClose
    } = this.props;
    const { search, result } = this.state;

    return (
      <StyledSelect
        ref={node => {
          this.outerRef = node;
        }}
        onClick={this.handleDropdown}
      >
        <div>
          {!value || dropdown ? (
            <SearchInput
              type="text"
              id="search"
              placeholder="Search Conversation"
              autoComplete="off"
              value={search}
              onChange={this.handleSearch}
              onKeyDown={this.onKeyDown}
              ref={node => {
                this.searchRef = node;
              }}
              autoFocus={autoFocus}
            />
          ) : (
            <Fragment>
              <Selection value={value} removeHandler={this.removeHandler} />{" "}
              <Icon icon="triangleDown" />
            </Fragment>
          )}
        </div>
        <div>
          {!dropdown ? null : (
            <Result
              text={search}
              focus={focus}
              result={result}
              excludeSelected={excludeSelected}
              value={value}
              outerRef={this.outerRef}
              handleSelect={this.handleSelect}
              handleClose={handleClose}
            />
          )}
        </div>
      </StyledSelect>
    );
  }
}

export default selector(Select);
