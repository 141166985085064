// @flow

import React, { useState } from "react";
import { InputRightElement, useDisclosure, Input } from "@chakra-ui/react";
import {
  InputGroup as StyledInputGroup,
  IconButton as StyledIconButton
} from "./styles";
import Icon from "src/icons";
import DeleteDomainModal from "./DeleteDomainModal";

type Props = {
  domain: String
};

const DomainContainer = ({ domain }: Props) => {
  const [showDelete, setShowDelete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <StyledInputGroup size="md">
        <Input pr="8.2rem" value={domain} isReadOnly={true} />
        <InputRightElement width="4.5rem">
          {showDelete ? (
            <StyledIconButton
              icon={<Icon type="newDelete" />}
              variant="unstyled"
              onClick={onOpen}
            />
          ) : null}
        </InputRightElement>
      </StyledInputGroup>
      <DeleteDomainModal isOpen={isOpen} onClose={onClose} domain={domain} />
    </div>
  );
};

export default DomainContainer;
