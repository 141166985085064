// @flow

import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import TextArea from "react-autosize-textarea";

import {
  ObjDisplay,
  ObjEdit,
  Objective as StyledObjective,
  SaveButton,
  SaveContainer
} from "./styles";
import { setAttribute } from "src/actions/chatroom";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  _setAttribute: Function,
  description: string
};

const Objective = ({ roomId, _setAttribute, description }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(description);

  const saveObjective = useCallback(() => {
    if (value !== description) {
      _setAttribute(roomId, {
        description: value
      });
    }
    setEditMode(false);
  }, [_setAttribute, roomId, value, description]);

  const handleEnter = useCallback(
    e => {
      if (e.keyCode === 13) {
        if (value !== description) {
          _setAttribute(roomId, {
            description: value
          });
        }
        setEditMode(false);
      }
    },
    [_setAttribute, roomId, value, description]
  );

  if (editMode === false) {
    return (
      <StyledObjective>
        <ObjDisplay onClick={() => setEditMode(true)}>
          {value || "Click here to set your objective"}
        </ObjDisplay>
      </StyledObjective>
    );
  }

  return (
    <StyledObjective>
      <ObjEdit>
        <TextArea
          value={value}
          autoFocus
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleEnter}
        />
      </ObjEdit>
      <SaveContainer>
        <SaveButton onClick={saveObjective}>Save</SaveButton>
      </SaveContainer>
    </StyledObjective>
  );
};

export default connect(null, {
  _setAttribute: setAttribute
})(Objective);
