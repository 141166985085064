// @flow

import * as atypes from "src/constants/actionTypes";

import type { Action } from "src/types";

const notificationChanged = (enabled: boolean): Action => ({
  type: atypes.NOTIFICATION_STATUS_CHANGED,
  payload: { enabled }
});

export { notificationChanged };
