// @flow

import styled from "@emotion/styled";

import { ListItemMedium } from "src/styles/box";
import { SearchResult as Result } from "src/styles/dropdown";

const SearchItem = styled(ListItemMedium)`
  > span > span {
    max-width: 14em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SearchResult = styled(Result)`
  > ul {
    max-height: 283px;
  }
`;

export { SearchItem, SearchResult };
