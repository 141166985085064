// @flow

import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { TypeHeader } from "./styles";
import ConversationType from "src/containers/conversation-types/Select";
import { conversationTypes } from "src/constants/display";
import { getChatroomType, getTemplateId, getUserRole } from "src/reducers";
import { changeConversationType } from "src/actions/chatroom";
import type { AppState, RoomId, WorkflowById } from "src/types";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

const defaultTypes = [
  { id: -1, title: "Process", invocationCount: null, label: "workflow" },
  { id: -2, title: "Task", invocationCount: null, label: "task" },
  { id: -3, title: "Approval", invocationCount: null, label: "approval" },
  { id: -4, title: "Feed", invocationCount: null, label: "group" }
];

type Props = {
  role: string,
  chatroomType: string,
  templateId: ?number,
  roomId: RoomId,
  templates: WorkflowById,
  _changeConversationType: Function
};

const Type = (props: Props) => {
  const getVal = () => {
    const { chatroomType, templateId } = props;
    if (chatroomType !== "workflow") {
      return R.head(conversationTypes[chatroomType]);
    }
    const { templates } = props;
    const template = templates[`${templateId || ""}`];
    return template ? template.title : `${templateId || ""}`;
  };

  const handleConversationType = (value: Object) => {
    const { roomId, _changeConversationType } = props;
    const { id, label } = value;

    if (id > 0) {
      _changeConversationType(roomId, "workflow", id);
    } else {
      _changeConversationType(roomId, label);
    }
  };

  const changeConversationTypeEnabled = useComponentPermission(
    componentPermissions.changeConversationType
  );

  if (changeConversationTypeEnabled) {
    return (
      <>
        <TypeHeader>Type</TypeHeader>
        <div>
          <ConversationType
            defaultTypes={defaultTypes}
            value={getVal()}
            handleChange={handleConversationType}
            excludeSelected
            autoFocus
          />
        </div>
      </>
    );
  }

  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  chatroomType: getChatroomType(app, props.roomId),
  templateId: getTemplateId(app, props.roomId),
  templates: app.workflow.byId,
  role: getUserRole(app)
});

export default connect(mapStateToProps, {
  _changeConversationType: changeConversationType
})(Type);
