// @flow

import { connect } from "react-redux";
import PushNotificationStatus from "../components/PushNotificationStatus";

import {
  getIsNotificationEnabled,
  getCurrentUserId,
  getRole
} from "src/reducers";
import { notificationChanged } from "../actions/notification";

import type { AppState } from "../types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  isNotificationEnabled: getIsNotificationEnabled(app),
  orgRole: getRole(app, getCurrentUserId(app))
});

export default connect(mapStateToProps, { notificationChanged })(
  PushNotificationStatus
);
