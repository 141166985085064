// @flow

import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import List from "./List";
import { RelativeBox as StyledSearch } from "src/styles/box";

type Props = {
  focus: number,
  text: string,
  value: string,
  excludeSelected: boolean,
  result: Array<number>,
  outerRef: any,
  handleClose: Function,
  handleSelect: Function
};

type State = {
  focus: number
};

class Result extends Component<Props, State> {
  state = {
    focus: 0
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleClickOutside = e => {
    const { outerRef, handleClose } = this.props;
    if (outerRef) {
      if (!outerRef.contains(e.target)) {
        handleClose();
      }
    }
  };

  handleKeyDown = (event: any) => {
    // Close Search when esc is pressed
    if (event.keyCode === 27) {
      const { handleClose } = this.props;
      handleClose();
    }
  };

  render() {
    const {
      text,
      focus,
      result,
      excludeSelected,
      value,
      outerRef,
      handleSelect
    } = this.props;

    return (
      <StyledSearch>
        <List
          text={text}
          focus={focus}
          result={result}
          outerRef={outerRef}
          value={value}
          excludeSelected={excludeSelected}
          handleSelect={handleSelect}
        />
      </StyledSearch>
    );
  }
}

export default onClickOutside(Result);
