// @flow

import * as React from "react";
import { useSelector } from "react-redux";

import { getCurrentChatroom } from "src/selectors";
import { getIsSrwMobile } from "src/reducers";

import { RoomContext } from "src/contexts";

import ActiveMain from "src/containers/ActiveMain";
import Chatrooms from "src/containers/chatroom-nav";
import Dock from "src/containers/CurrentRoomDock";

import { Body as StyledBody } from "src/styles";

const Body = () => (
  <StyledBody>
    <Chatrooms />
    <RoomContent />
  </StyledBody>
);

export default Body;

const RoomContent = () => {
  const roomId = useSelector(getCurrentChatroom);
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  return (
    <RoomContext.Provider value={roomId}>
      <ActiveMain />
      {isSrwMobile && <Dock />}
    </RoomContext.Provider>
  );
};
