// @flow

import React from "react";

import {
  Item as StyledItem,
  ItemIcon,
  ItemInfo,
  ItemLabel,
  ItemValue,
  UserIcon
} from "./styles";
import Parent from "./Parent";
import User from "src/components/user/Photo";
import NameOrEmail from "src/containers/user/NameOrEmail";
import Icon from "src/icons";
import type { UID } from "src/types";

type Props = {
  label: string,
  value: string,
  icon: string,
  owner?: ?UID,
  handleClick?: Function,
  location?: string
};

const Item = ({ label, value, icon, owner, handleClick, location }: Props) => {
  let itemValue;
  if (label === "Owner")
    itemValue = owner ? <NameOrEmail uid={owner} /> : "Owner not assigned";
  else itemValue = value;
  return (
    <>
      <StyledItem
        parent={value === "parent"}
        onClick={() => (handleClick ? handleClick(location) : null)}
      >
        <ItemIcon>
          {icon === "photo" ? (
            <UserIcon>
              <User uid={owner} size="sm" shape="ellipse" />
            </UserIcon>
          ) : (
            <Icon type={icon} />
          )}
        </ItemIcon>
        <ItemInfo>
          <ItemLabel>{label}</ItemLabel>
          {value !== "parent" ? <ItemValue>{itemValue}</ItemValue> : null}
          {value === "parent" ? <Parent /> : null}
        </ItemInfo>
        {value !== "parent" ? <Icon type="caretRight" /> : null}
      </StyledItem>
    </>
  );
};

Item.defaultProps = {
  owner: null,
  handleClick: () => null
};

export default Item;
