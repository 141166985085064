// @flow

import { connect } from "react-redux";

import Main from "src/components/Main";

import * as actions from "src/actions";

const ActiveMain = connect(null, {
  ...actions
})(Main);

export default ActiveMain;
