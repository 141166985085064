// @flow

import { StackProps, BoxProps } from "@chakra-ui/react";

import * as colors from "src/styles/constants/colors";

export const container: StackProps = {
  border: "1px solid",
  p: 2,
  width: "100%"
};

export const verticalContainer: StackProps = {
  alignItems: "start",
  width: "100%"
};

export const defaultContainer: StackProps = {
  borderColor: colors.grey2,
  gap: "0.25rem"
};

export const highlightedContainer: StackProps = {
  borderColor: colors.active
};

const commonTitleRowStyles: StackProps = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  gridGap: 1,

  svg: {
    cursor: "pointer",
    visibility: "hidden"
  },
  _hover: {
    svg: {
      visibility: "visible"
    }
  },

  flex: 1,
  minWidth: 0,
  a: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};

export const titleRow: StackProps = {
  ...commonTitleRowStyles
};

export const titleRowExpanded: StackProps = {
  ...commonTitleRowStyles,
  svg: {
    ...commonTitleRowStyles.svg,
    visibility: "visible"
  },
  justifyContent: "stretch"
};

export const expandedTitleContainer: BoxProps = {
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: colors.highlight,
  padding: "4px",
  borderRadius: "4px"
};

export const revisionIcon = {
  visibility: "visible"
};
