// @flow

import { connect } from "react-redux";
import React, { useRef, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Item as StyledItem,
  ItemLabel,
  ItemIcon,
  ItemInfo,
  ConvoID
} from "./styles";
import Icon from "src/icons";
import { CopyButton } from "src/styles/buttons";
import { generateChatroomEmail } from "src/actions/email";
import { getChatRoomEmail } from "src/reducers";

import type { RoomId, AppState } from "src/types";

type Props = {
  email: string,
  roomId: RoomId,
  _generateChatroomEmail: Function
};

const Email = ({ email, roomId, _generateChatroomEmail }: Props) => {
  const textAreaRef: Object = useRef(null);

  useEffect(() => {
    // If no email is present during mount
    if (email === "" && roomId) {
      _generateChatroomEmail(roomId);
    }
  }, [roomId, _generateChatroomEmail]);

  const copyToClipboard = useCallback(() => {
    toast.success("copied");
  }, []);

  return (
    <StyledItem email>
      <ItemIcon>
        <Icon type="email" />
      </ItemIcon>
      <ItemInfo>
        <ItemLabel>Send an email to conversation</ItemLabel>
        <ConvoID ref={textAreaRef} value={email} readOnly />
      </ItemInfo>
      <CopyToClipboard text={email} onCopy={copyToClipboard}>
        <CopyButton type="button">
          <Icon type="copy" />
        </CopyButton>
      </CopyToClipboard>
    </StyledItem>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  email: getChatRoomEmail(app)
});

export default connect(mapStateToProps, {
  _generateChatroomEmail: generateChatroomEmail
})(Email);
