// @flow

import React from "react";
import { connect } from "react-redux";
import { GridItem } from "@chakra-ui/layout";

import { Files as StyledFiles, FileHeader } from "./styles";
import File from "./File";
import Icon from "src/icons";
import * as actions from "src/actions/file";
import { getRoomFileIds } from "src/reducers";
import type { AppState, RoomId } from "src/types";
import DockLayout from "src/components/Dock/DockLayout";

type Props = {
  roomId: RoomId,
  fileCount: any,
  files: Array<string>
};

const Files = ({ fileCount, files, roomId }: Props) => {
  return (
    <StyledFiles>
      <FileHeader>
        <Icon type="files" />
        <strong>{fileCount} Files</strong>
      </FileHeader>
      <DockLayout>
        {() => {
          return files
            .filter(file => file !== undefined)
            .map(file => (
              <GridItem key={file} colSpan={3}>
                <File key={file} name={file} roomId={roomId} />
              </GridItem>
            ));
        }}
      </DockLayout>
    </StyledFiles>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }: Props) => ({
  files: getRoomFileIds(app, roomId)
});

export default connect(mapStateToProps, actions)(Files);
