// @flow

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as R from "ramda";
import moment from "moment";
import TypingItem from "./TypingItem";
import { getChatroomTyping } from "src/reducers";

import type { AppState, UserTyping, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  typing: Array<UserTyping>
};

const getTypingWithinTenSeconds = typing =>
  R.filter(user => {
    return moment().diff(user.lastTyped, "seconds") <= 2;
  }, typing);

const TypingIndicator = ({ typing, roomId }: Props) => {
  const [recomputedTyping, setTyping] = useState("");

  useEffect(() => {
    setTyping(getTypingWithinTenSeconds(typing));
  }, [typing, getTypingWithinTenSeconds]);

  return (
    <>
      {recomputedTyping
        ? recomputedTyping.map(user => (
            <TypingItem
              uid={user.uid}
              roomId={roomId}
              key={user.uid}
              typing={typing}
              recomputedTyping={recomputedTyping}
            />
          ))
        : null}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { roomId }) => ({
  typing: getChatroomTyping(app, roomId)
});

export default connect(mapStateToProps)(TypingIndicator);
