// @flow

import * as React from "react";
import { connect } from "react-redux";

import NavBar from "./filter/NavBar/Index";
import ErrorBoundary from "./ErrorBoundary";

import Overlay from "./Overlay";
import PushNotificationStatus from "src/containers/PushNotificationStatus";
import Body from "src/components/Body";
import SRW from "src/components/SRW";
import { HomeContainer } from "src/styles";
import type { Location } from "src/types";

type Props = {
  query: {
    authCode?: string
  }
};
const Home = ({ query }: Props) => {
  const { authCode } = query || {};
  if (authCode) return <SRW />;
  return (
    <ErrorBoundary>
      <PushNotificationStatus />
      <Overlay />
      <HomeContainer>
        <NavBar />
        <Body />
      </HomeContainer>
    </ErrorBoundary>
  );
};

const mapStateToProps = ({ location }: { location: Location }) => ({
  query: location.query
});

export default connect(mapStateToProps)(Home);
