// @flow

import React, { useState } from "react";
import { connect } from "react-redux";
import DomainContainer from "./DomainContainer";
import AddDomain from "./AddDomain";
import { FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { getCurrentOrg } from "src/reducers";
import * as colors from "src/styles/constants/colors";
import { Button as StyledButton } from "./styles";
import type { AppState } from "src/types";

type Props = {
  currentOrgDetails: Object
};

const OrgDomains = ({ currentOrgDetails }: Props) => {
  const [isAddDomainVisible, setIsAddDomainVisible] = useState(false);
  const { allDomains = [] } = currentOrgDetails;
  return (
    <FormControl as="div">
      <FormLabel color={colors.inkLighter} textTransform="uppercase">
        Org Domain
      </FormLabel>
      <Stack direction="column" spacing={2} align="flex-start">
        {allDomains.map(domain => {
          return <DomainContainer key={domain} domain={domain} />;
        })}
        {isAddDomainVisible ? (
          <AddDomain setIsAddDomainVisible={setIsAddDomainVisible} />
        ) : null}
        <StyledButton onClick={() => setIsAddDomainVisible(true)}>
          + Add new domain
        </StyledButton>
      </Stack>
    </FormControl>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentOrgDetails: getCurrentOrg(app)
});

export default connect(mapStateToProps)(OrgDomains);
