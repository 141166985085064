// @flow

import React from "react";
import * as R from "ramda";

import BreadCrumbs from "./styles";
import BreadCrumb from "src/components/breadcrumbs/BreadCrumb";

import type { ChatRoomDepth } from "src/types";

type Props = {
  nolink?: boolean,
  breadCrumb: ChatRoomDepth
};

const Long = ({ breadCrumb, nolink }: Props) => {
  const orderedBreadCrumb: ChatRoomDepth = breadCrumb
    ? R.reverse(breadCrumb)
    : [];

  const head: number = R.head(orderedBreadCrumb);
  const body: ChatRoomDepth =
    orderedBreadCrumb.length > 2
      ? R.drop(1, R.dropLast(1, orderedBreadCrumb))
      : R.drop(1, orderedBreadCrumb);
  const tail: ?number =
    orderedBreadCrumb.length > 2 ? R.last(orderedBreadCrumb) : null;
  if (orderedBreadCrumb.length === 0) return null;

  return (
    <BreadCrumbs>
      {/* HEAD */}
      {head ? (
        <div>
          <BreadCrumb id={`${head}`} nolink={nolink} />
        </div>
      ) : null}

      {/* BODY */}
      <div>
        {R.map(
          room => (
            <BreadCrumb id={`${room}`} key={room} nolink={nolink} />
          ),
          body
        )}
      </div>

      {/* TAIL */}
      {tail ? (
        <div>
          <BreadCrumb id={tail} nolink={nolink} />
        </div>
      ) : null}
    </BreadCrumbs>
  );
};

Long.defaultProps = {
  nolink: false
};

export default Long;
