// @flow

import { connect } from "react-redux";

import List from "src/components/chatroom-nav";
import * as actions from "src/actions";
import {
  setWorkflowFilter,
  setSingleType,
  clearType
} from "src/actions/filter";
import { set as setActiveFilter } from "src/actions/activeFilter";

export default connect(null, {
  ...actions,
  setActiveFilter,
  setWorkflowFilter,
  setSingleType,
  clearType
})(List);
