// @flow

import React, { Component } from "react";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";

import Icon from "src/components/Icon";
import DropdownMenu from "src/styles/dropdown";
import { Error } from "src/styles";
import { conversationTypes } from "src/constants/display";
import { iconSize } from "src/styles/constants/size.old";
import * as colors from "src/styles/constants/colors.old";

import type { Workflow } from "src/types";

type Props = {
  error: string,
  draft: ?boolean,
  workflows: Array<Workflow>,
  exclude: Array<Workflow>,
  outerRef: any,
  handleSelect: Function,
  handleClose: Function,
  createWorkflow: Function,
  clearError: Function,
  handleConversation: Function
};

type State = {
  searchTerm: string,
  matches: Array<Workflow>,
  otherConversations: Array<string>
};

const conversations = R.keys(conversationTypes);

class Search extends Component<Props, State> {
  input: any;
  state = {
    searchTerm: "",
    matches: [],
    otherConversations: R.reject(
      c => c === "workflow" || c === "task",
      conversations
    )
  };

  componentDidMount() {
    const { workflows } = this.props;
    if (workflows.length !== 0) {
      this.setState({ matches: workflows });
    }
    if (this.input) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { workflows } = this.props;
    if (prevProps.workflows.length !== workflows.length) {
      const { searchTerm } = this.state;
      this.setState({
        matches: workflows.filter(x =>
          R.includes(R.toLower(searchTerm), R.toLower(x.title))
        )
      });
    }
  }

  handleSelect = (w: Object) => {
    const { handleSelect } = this.props;
    handleSelect(w.id);
    this.setState({
      searchTerm: "",
      matches: [],
      otherConversations: R.reject(
        c => c === "workflow" || c === "task",
        conversations
      )
    });
  };

  handleSelectConversation = (type: string) => {
    const { handleConversation } = this.props;
    handleConversation(type);
    this.setState({
      searchTerm: "",
      matches: [],
      otherConversations: R.reject(
        c => c === "workflow" || c === "task",
        conversations
      )
    });
  };

  handleClickOutside = (e: MouseEvent) => {
    if (e) {
      const { handleClose, outerRef } = this.props;
      if (outerRef) {
        if (!outerRef.contains(e.target)) {
          handleClose();
        }
      }
    }
  };

  search = (event: SyntheticInputEvent<any>) => {
    const { value } = event.target;
    const { error, workflows, clearError } = this.props;
    const searchTerm = R.toLower(value);
    const matches = workflows.filter(x =>
      R.includes(searchTerm, R.toLower(x.title))
    );

    const otherConversations = R.filter(
      c =>
        c !== "workflow" &&
        c !== "task" &&
        R.includes(searchTerm, R.toLower(R.head(conversationTypes[c]))),
      conversations
    );

    if (error) {
      clearError("inlineWorkflow");
    }

    this.setState({
      searchTerm: value,
      matches: workflows.length > 0 && !R.isEmpty(value) ? matches : [],
      otherConversations
    });
  };

  defineDraft = () => {
    const { createWorkflow, clearError } = this.props;
    const { searchTerm } = this.state;
    clearError("inlineWorkflow");
    if (searchTerm) {
      createWorkflow({
        draft: true,
        title: searchTerm,
        numberingScheme: `${searchTerm} ###`
      });
    }
  };

  render() {
    const { error, draft, exclude } = this.props;
    const { searchTerm, matches, otherConversations } = this.state;

    return (
      <DropdownMenu>
        <input
          type="text"
          placeholder="Search"
          autoComplete="off"
          value={searchTerm}
          onChange={this.search}
          ref={input => {
            this.input = input;
          }}
          autoFocus
        />

        <ul>
          {R.map(
            w => (
              <li key={w.id} onClick={() => this.handleSelect(w)}>
                <Icon icon="process" />
                {w.title}
              </li>
            ),
            R.filter(w => !R.includes(w.id, exclude), matches)
          )}

          {R.map(
            c => (
              <li
                key={c}
                onClick={() => this.handleSelectConversation(c)}
                onKeyPress={() => this.handleSelectConversation(c)}
                tabIndex="0"
                role="button"
              >
                <Icon icon={c} size={iconSize.big} color={colors.black} />
                {R.head(conversationTypes[c])}
              </li>
            ),
            otherConversations
          )}
        </ul>

        {error ? <Error>{error}</Error> : null}
        {draft ? (
          <button type="button" onClick={this.defineDraft}>
            <span>
              Define <strong>{searchTerm}</strong> Draft Process
            </span>
          </button>
        ) : null}
      </DropdownMenu>
    );
  }
}

export default onClickOutside(Search);
