// @flow
import { connect } from "react-redux";

import { initialFilterState } from "src/reducers/chatRooms";
import MyConversation from "src/components/filter/Item";

import { showView } from "src/actions";
import { setFilter, updateFilter } from "src/actions/filter";
import { set as setActiveFilter } from "src/actions/activeFilter";
import { setPreviousChatroom } from "src/actions/chatroom";
import {
  getCurrentRoomId,
  getChatroomAddress,
  getWorkflowBuilderShown
} from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => {
  const currentChatroom = getCurrentRoomId(app);
  return {
    view: app.view,
    filter: initialFilterState,
    address: getChatroomAddress(app, currentChatroom),
    show: getWorkflowBuilderShown(app)
  };
};

export default connect(mapStateToProps, {
  setFilter,
  setActiveFilter,
  showView,
  updateFilter,
  setPreviousChatroom
})(MyConversation);
