// @flow

import React, { useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import {
  MessageText,
  ToogleText,
  AssistantTitle,
  MutedToogleText
} from "./styles";
import {
  AssistantIcon,
  ReminderPrompt
} from "src/components/messages/Prompt/styles";
import { ChatMessage, FlexSpacer } from "src/components/messages/Chat/styles";
import {
  selectMessage,
  deselectMessage,
  hideSelectOptions,
  showMobileOptions
} from "src/actions/chatmenu";
import WarningModal from "src/components/Modal/Warning";
import MessageCard from "src/components/messages/Chat/MessageNotch";

import Icon from "src/icons";
import ChatTimestamp from "src/components/messages/Chat/ChatTimestamp";
import NewMenu from "src/components/messages/NewMenu";
import SelectionIndicator from "src/components/messages/Chat/SelectIndicator";
import {
  getChatroomReminders,
  getChatroomMembership,
  getSelectedAction
} from "src/reducers";

import { setAttribute } from "src/actions/chatroom";
import MessageWithMentions from "src/components/messages/MessageWithMentions";
import useBoolean from "src/hooks/useBoolean";

import type { AppState, Message, UID, MessageID } from "src/types";

type Props = {
  message: Message,
  _setAttribute: Function,
  reminders: boolean,
  selectedAction: string,
  isSrwMobile: boolean,
  isMember: boolean,
  currentUser: UID,
  _selectMessage: Function,
  _deselectMessage: Function,
  _hideSelectOptions: Function,
  _showMobileOptions: Function,
  selectedMessages: Array<MessageID>
};

const ReminderUniBot = ({
  message,
  _setAttribute,
  reminders,
  selectedAction,
  isSrwMobile,
  currentUser,
  _selectMessage,
  _deselectMessage,
  _hideSelectOptions,
  _showMobileOptions,
  selectedMessages
}: Props) => {
  const [menu, setMenu] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const { data, roomId, timestamp, delivered, success, author, id } = message;

  const {
    value: modal,
    setTrue: showModal,
    setFalse: hideModal
  } = useBoolean();
  const outerRef = useRef();

  const turnOffReminders = () => {
    _setAttribute(roomId, { reminders: false });
    hideModal();
  };

  // Render action menu when user is deleting or forwarding message(s)
  const showMenuActions = useCallback(() => {
    const currentActionFilter =
      selectedAction.length == 0 ||
      (selectedAction !== "DELETE" && selectedAction !== "FORWARD");

    if (currentActionFilter) setMenu(true);
    else setMenu(false);
  }, [menu, submenu, selectedAction]);

  const isOwner = currentUser === author;
  const isMessageSelected = selectedMessages.includes(id);

  const toggleMenu = useCallback(() => {
    if (selectedAction) {
      if (selectedAction === "MOBILE") {
        if (!isMessageSelected) {
          _hideSelectOptions();
          _showMobileOptions();
          _selectMessage(id, timestamp, isOwner);
        } else _deselectMessage(id);
      } else if (!isMessageSelected) _selectMessage(id, timestamp, isOwner);
      else _deselectMessage(id);
    }
  }, [selectedAction, id, timestamp, isOwner, isMessageSelected]);

  return (
    <ReminderPrompt
      onMouseEnter={showMenuActions}
      onMouseLeave={() => !submenu && setMenu(false)}
      isSrwMobile={isSrwMobile}
      onClick={toggleMenu}
      first
      showSelect={selectedAction && selectedAction !== "MOBILE"}
    >
      {modal && (
        <WarningModal
          handleClose={hideModal}
          handleClick={turnOffReminders}
          heading="Turn off reminders"
          content="Are you sure you want to turn off reminders?"
          primaryText="Turn off"
          secondaryText="Cancel"
          outerRef={outerRef}
        />
      )}
      <ChatMessage id={id} showSelect={selectedAction}>
        <AssistantIcon>
          <Icon type="assistant" />
        </AssistantIcon>
        <MessageCard first maxWidth>
          <AssistantTitle>Unifize Assistant</AssistantTitle>
          <MessageText>
            <MessageWithMentions message={data || ""} />
          </MessageText>

          {R.isNil(reminders) || reminders ? (
            <ToogleText onClick={showModal}>Turn off reminders</ToogleText>
          ) : (
            <MutedToogleText>Reminders have been turned off</MutedToogleText>
          )}

          <ChatTimestamp
            read={success}
            delivered={delivered}
            timestamp={timestamp}
          />
        </MessageCard>
        <FlexSpacer />
        {menu && !isSrwMobile ? (
          <NewMenu
            id={id}
            submenu={submenu}
            setMenu={setMenu}
            setSubmenu={setSubmenu}
            timestamp={timestamp}
            isOwner={isOwner}
            author={author}
          />
        ) : null}
        <SelectionIndicator id={id} timestamp={timestamp} isOwner={isOwner} />
      </ChatMessage>
    </ReminderPrompt>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, { message }) => ({
  reminders: getChatroomReminders(app, message.roomId),
  selectedAction: getSelectedAction(app),
  isSrwMobile: app.srw.isMobile,
  isMember: getChatroomMembership(app, app.chatRooms.current),
  currentUser: app.currentUser.uid,
  selectedMessages: app.chatRooms.selectedMessages
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute,
  _selectMessage: selectMessage,
  _deselectMessage: deselectMessage,
  _hideSelectOptions: hideSelectOptions,
  _showMobileOptions: showMobileOptions
})(ReminderUniBot);
