// @flow

import { connect } from "react-redux";
import React from "react";

import { Dock as StyledDock, Content } from "../styles";
import Info from "../Info";
import ObjectiveSection from "../Objective";
import { getDescription } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  room: RoomId,
  activeDock: string,
  isSrw: boolean,
  description: string
};

const Objective = ({ activeDock, room, isSrw, description }: Props) => (
  <StyledDock open={activeDock}>
    <Info title="Objective" back isSrw={isSrw} />
    <Content>
      <ObjectiveSection roomId={room} description={description} />
    </Content>
  </StyledDock>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  description: getDescription(app, props.room)
});

export default connect(mapStateToProps)(Objective);
