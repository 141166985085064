// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Parent as StyledParent } from "./styles";
import { getParent } from "src/reducers";
import { setAttribute } from "src/actions/chatroom";
import Select from "src/containers/chatroom/select";
import type { AppState, RoomId } from "src/types";

type Props = {
  id: RoomId,
  parent: any,
  _setAttribute: Function
};

const Parent = ({ id, parent, _setAttribute }: Props) => {
  const handleChange = useCallback(
    (newParent: string) => {
      _setAttribute(id, { parent: parseInt(newParent, 10) });
    },
    [id, _setAttribute]
  );

  return (
    <StyledParent parent={parent === null}>
      <Select
        value={parent}
        handleChange={handleChange}
        placeholder="Select Parent"
      />
    </StyledParent>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => {
  const id = app.chatRooms.current;
  return {
    id,
    parent: getParent(app, id)
  };
};

export default connect(mapStateToProps, { _setAttribute: setAttribute })(
  Parent
);
