// @flow

import { connect } from "react-redux";

import NewRoom from "src/components/chatroom/NewRoom";
import {
  setConversationAttribute,
  setConversationAttributes,
  clearConversationDialog,
  setNewWorkflow
} from "src/actions/chatroom";
import {
  getCurrentFilter,
  getCurrentRoomId,
  getComponentPermission
} from "src/reducers";
import { componentPermissions } from "src/constants/roleManagement";
import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  systemProcess: app.systemProcess,
  filter: getCurrentFilter(app),
  roomId: getCurrentRoomId(app),
  chatroomCreationEnabled: getComponentPermission(
    app,
    componentPermissions.newConversation
  )
});

export default connect(mapStateToProps, {
  setConversationAttributes,
  setConversationAttribute,
  clearConversationDialog,
  setNewWorkflow
})(NewRoom);
