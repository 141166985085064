// @flow
import React, { useState, useCallback } from "react";
import type { ContentRect } from "react-measure";
import Measure from "react-measure";

import { Grid, VStack } from "@chakra-ui/layout";

import type { Breakpoint } from "src/types";
import { breakpoints, breakpointMaxWidths } from "./utils";

import * as styles from "./styles";

type Props = {
  children: (args: { breakpoint: Breakpoint }) => React$Node
};

const DockLayout = ({ children }: Props) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(breakpoints.small);
  const handleResize = useCallback(
    (contentRect: ContentRect) => {
      const width = contentRect.bounds.width + 1;
      if (width >= 700) {
        setBreakpoint(breakpoints.large);
      } else if (width >= 500) {
        setBreakpoint(breakpoints.medium);
      } else {
        setBreakpoint(breakpoints.small);
      }
    },
    [setBreakpoint]
  );
  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <VStack ref={measureRef} sx={styles.WideChecklistContainer}>
          <Grid
            templateColumns={
              breakpointMaxWidths[breakpoint] === 1
                ? "100%"
                : `repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`
            }
            gap={"0.5rem"}
            sx={{
              width: "100%"
            }}
          >
            {children({ breakpoint })}
          </Grid>
        </VStack>
      )}
    </Measure>
  );
};

export default DockLayout;
