// @flow

import React from "react";
import { connect } from "react-redux";

import { Workflow as StyledWorkflow } from "./styles";
import Name from "src/containers/workflow/Name";
import { getTemplateId, getSequenceNo, getChatroomType } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  templateId: number,
  seqNo: number,
  type: string
};

const Workflow = ({ templateId, seqNo, type }: Props) => {
  return (
    <StyledWorkflow>
      {templateId && type === "workflow" && (
        <>
          <Name id={templateId} /> #{seqNo}:{" "}
        </>
      )}
    </StyledWorkflow>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  type: getChatroomType(app, props.roomId),
  templateId: getTemplateId(app, props.roomId),
  seqNo: getSequenceNo(app, props.roomId)
});

export default connect(mapStateToProps)(Workflow);
