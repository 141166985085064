// @flow

import React from "react";

import { OldValue, ValueList } from "../styles";
import LinkedRoom from "src/components/messages/Chat/Notification/LinkedRoom";

import type { RoomId, ChatroomAddress } from "src/types";

type Props = {
  oldValue: Array<RoomId>,
  oldAddresses: Array<ChatroomAddress>
};

const OldConversationValues = ({ oldValue, oldAddresses }: Props) => {
  return (
    <OldValue>
      <ValueList>
        {oldValue.map((val, id) => (
          <LinkedRoom
            id={`${val}`}
            // eslint-disable-next-line react/no-array-index-key
            key={id}
            chatroomAddress={oldAddresses[id]}
          />
        ))}
      </ValueList>
    </OldValue>
  );
};

export default OldConversationValues;
