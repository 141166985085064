// @flow

import React, { useCallback } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import onClickOutside from "react-onclickoutside";

import { Dropdown as StyledDropdown, DropdownItem } from "./styles";
import Upload from "src/containers/chatroom/UploadCurrentRoom";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { unpinFile, downloadFile } from "src/actions/file";
import type { UnifizeFile } from "src/types";
import { getCurrentRoomId } from "src/reducers";

type Props = {
  setShowDropdown: Function,
  file: UnifizeFile,
  _unpinFile: Function
};

const Dropdown = ({ setShowDropdown, file, _unpinFile }: Props) => {
  const dispatch = useDispatch();
  const roomId = useSelector(({ app }) => getCurrentRoomId(app));

  Dropdown.handleClickOutside = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const removeFile = useCallback(() => {
    _unpinFile(file.name);
    setShowDropdown(false);
  }, [_unpinFile, setShowDropdown, file]);

  const handleDownload = () => {
    dispatch(
      downloadFile({
        roomId,
        location: "activity-panel",
        name: file.name,
        originalName: file.originalName,
        generation: file.generation,
        versionId: file.versionId
      })
    );
  };

  return (
    <StyledDropdown>
      <Upload
        location="activity-panel"
        render={openFileBrowser => (
          <DropdownItem
            onClick={openFileBrowser}
            role="button"
            tabIndex="0"
            onKeyDown={openFileBrowser}
          >
            Upload New Version
            <Icon type="upload" />
          </DropdownItem>
        )}
        togglePopover={() => setShowDropdown(false)}
        originalFile={file}
      />
      <div onClick={handleDownload}>
        <DropdownItem>
          Download
          <Icon type="download" />
        </DropdownItem>
      </div>
      <DropdownItem onClick={removeFile}>
        Delete
        <Icon type="delete" color={colors.danger} />
      </DropdownItem>
    </StyledDropdown>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Dropdown.handleClickOutside
};

export default connect(null, {
  _unpinFile: unpinFile
})(onClickOutside(Dropdown, clickOutsideConfig));
