// @flow

import { connect } from "react-redux";
import React, { memo } from "react";

import { ChatBlockWrapper } from "./styles";
import ChatBlock from "src/containers/chatroom-nav/ChatBlock";
import { getIsCurrentRoom } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  isCurrentRoom: boolean,
  roomId: RoomId
};

const Chat = ({ isCurrentRoom, roomId }: Props) => (
  <ChatBlockWrapper key={roomId} role="listitem" active={isCurrentRoom}>
    <ChatBlock key={roomId} roomId={roomId} />
  </ChatBlockWrapper>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  isCurrentRoom: getIsCurrentRoom(app, props.roomId)
});

export default connect(mapStateToProps)(memo<Props>(Chat));
