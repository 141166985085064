// @flow

import React, { Component } from "react";
import * as R from "ramda";
import { findDOMNode } from "react-dom";

import { SearchItem } from "./styles";
import { Count } from "src/styles";

type Props = {
  focus: boolean,
  room: {
    id: number,
    title: string,
    type: string,
    invocationCount: ?number
  },
  handleSelect: Function
};

class Item extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { focus } = this.props;
    if (prevProps.focus !== focus) {
      if (focus) {
        // eslint-disable-next-line react/no-find-dom-node
        const target = findDOMNode(this);
        // $FlowFixMe
        target.parentNode.scrollTop = target.offsetTop - 5;
      }
    }
  }

  selectItem = (e: any) => {
    const { room, handleSelect } = this.props;
    e.stopPropagation();
    handleSelect(room);
  };

  render() {
    const { focus, room } = this.props;
    return (
      <SearchItem
        tabIndex="0"
        onClick={this.selectItem}
        onKeyPress={this.selectItem}
        focus={focus}
        aria-selected={focus}
      >
        <div>
          <span>{room.title}</span>
          {!R.isNil(room.invocationCount) && (
            <Count>
              <span>{room.invocationCount}</span>
            </Count>
          )}
        </div>
      </SearchItem>
    );
  }
}

export default Item;
