// @flow

import React from "react";

import { useSelector } from "react-redux";
import { NewValue, ValueList } from "../styles";
import LinkedRoom from "src/components/messages/Chat/Notification/LinkedRoom";

import type { RoomId } from "src/types";
import { getChatRoomsById } from "src/reducers";

type Props = {
  newValue: Array<RoomId>
};

const NewConversationValue = ({ newValue }: Props) => {
  const chatRooms = useSelector(({ app }) => getChatRoomsById(app));
  const newAddresses = newValue.map(id => chatRooms[id]) || [];
  return (
    <>
      {newValue.length > 0 && (
        <NewValue>
          <ValueList>
            {newValue.map((val, id) => (
              <LinkedRoom
                id={`${val}`}
                // eslint-disable-next-line react/no-array-index-key
                key={id}
                chatroomAddress={newAddresses[id]}
              />
            ))}
          </ValueList>
        </NewValue>
      )}
    </>
  );
};

export default NewConversationValue;
