// @flow

export const getChipWidth = (fieldWidth: number) => {
  if (fieldWidth) {
    if (fieldWidth < 360) return `${fieldWidth / 9}px`;
    else if (fieldWidth < 600) return `${fieldWidth / 9}px`;
    else if (fieldWidth < 900) return `${fieldWidth / 8}px`;
    else return `${fieldWidth / 8}px`;
  }
};
