// @flow

import { connect } from "react-redux";
import React from "react";

import { Dock as StyledDock, Content } from "../styles";
import Info from "../Info";
import FileSection from "../Files";
import { getChatroomFileCount } from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  room: RoomId,
  activeDock: string,
  isSrw: boolean,
  fileCount: number
};

const Files = ({ activeDock, isSrw, fileCount, room }: Props) => (
  <StyledDock open={activeDock}>
    <Info title="Files" back isSrw={isSrw} />
    <Content>
      <FileSection fileCount={fileCount} roomId={room} />
    </Content>
  </StyledDock>
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  fileCount: getChatroomFileCount(app, props.room)
});

export default connect(mapStateToProps)(Files);
