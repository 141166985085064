// @flow

import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from "@chakra-ui/react";
import * as colors from "src/styles/constants/colors";
import { deleteOrgDomain } from "src/actions/orgSettings";
import { getCurrentOrg } from "src/reducers";
import type { AppState } from "src/types";

type Props = {
  orgId: number,
  domain: String,
  _deleteOrgDomain: Function,
  onClose: Function,
  isOpen: Boolean
};

const DeleteDomainModal = ({
  _deleteOrgDomain,
  orgId,
  domain,
  onClose,
  isOpen
}: Props) => {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor={colors.grey0}>
          Delete Org Domain
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this org domain?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              onClose();
              _deleteOrgDomain(orgId, domain);
            }}
          >
            Delete
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  orgId: getCurrentOrg(app).id
});

export default connect(mapStateToProps, {
  _deleteOrgDomain: deleteOrgDomain
})(DeleteDomainModal);
