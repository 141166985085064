// @flow

import { connect } from "react-redux";

import Dock from "src/components/Dock";
import { getCurrentRoomId } from "src/reducers";

import type { AppState } from "src/types";

const mapStateToProps = ({ app }: { app: AppState }) => ({
  room: getCurrentRoomId(app)
});

export default connect(mapStateToProps)(Dock);
