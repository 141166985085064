import { useLayoutEffect, useState } from "react";

/**
 * Check if an element overflows
 *
 * For more context:
 * https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
 */
const useOverflowCheck = (ref, callback) => {
  const [hasOverflown, setOverflown] = useState(null);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setOverflown(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return hasOverflown;
};

export default useOverflowCheck;
