// @flow

import { connect, useDispatch } from "react-redux";
import React, { useCallback, useEffect } from "react";

import {
  Dock as StyledDock,
  Content,
  Context,
  BreadcrumbWrapper,
  ItemList,
  LeaveContainer
} from "../styles";
import InfoSection from "../Info";
import Workflow from "../Workflow";
import Title from "../Title";
import Item from "../Item";
import Email from "../Email";
import Priority from "../Priority";
import Reminders from "../Reminders";
import DueDate from "../DueDate";
import Type from "../Type";
import BreadCrumbs from "src/containers/BreadCrumbs";
import Icon from "src/icons";
import { showDock, showSrwDock, toggleDock } from "src/actions/dock";
import { toggleRelatedModal } from "src/actions/modal";
import { removeRoomMember } from "src/actions/chatroom";
import {
  setPriorityTrigger,
  setOwnerTriggerFromInfoPanel
} from "src/actions/uiTriggers";
import { toggleParticipants } from "src/actions/header";
import {
  getChatroomMembership,
  getCurrentUserId,
  getChatroomTitle,
  getChatroomType,
  getChatroomOwner,
  getChatroomChecklists,
  getChatroomPrivacy,
  getDescription,
  getChatroomFileCount,
  getTemplateId
} from "src/reducers";
import useBoolean from "src/hooks/useBoolean";

import type { AppState, RoomId, UID } from "src/types";

type Props = {
  room: RoomId,
  activeDock: string,
  isSrw: boolean,
  users: Array<UID>,
  membership: boolean,
  privacy: string,
  uiTriggers: any,
  chatroomChecklists: any,
  title: ?string,
  currentUser: UID,
  owner: UID,
  type: string,
  description: string,
  parent: any,
  child: any,
  fileCount: number,
  ownerDropdown: boolean,
  templateId: ?number,
  _toggleDock: Function,
  _toggleRelatedModal: Function,
  _showSrwDock: Function,
  _showDock: Function,
  _participantTrigger: Function,
  _setPriorityTrigger: Function,
  _setOwnerTriggerFromInfoPanel: Function
};

const Info = ({
  room,
  activeDock,
  isSrw,
  type,
  users,
  owner,
  parent,
  child,
  uiTriggers,
  privacy,
  fileCount,
  currentUser,
  title,
  templateId,
  _showSrwDock,
  _showDock,
  description,
  chatroomChecklists,
  _toggleRelatedModal,
  _participantTrigger,
  _setPriorityTrigger,
  _setOwnerTriggerFromInfoPanel
}: Props) => {
  const { value: ownerDropdown, toggleBoolean } = useBoolean();
  const { status: statusTrigger } = uiTriggers;
  const dispatch = useDispatch();

  useEffect(() => {
    _setOwnerTriggerFromInfoPanel(ownerDropdown);
  }, [ownerDropdown]);

  let totalChecklistFields = 0;
  if ((chatroomChecklists || []).length > 0) {
    const primaryChecklist = chatroomChecklists[0];
    const { progress, fieldCount } = primaryChecklist;
    if (fieldCount) {
      totalChecklistFields = fieldCount;
    } else if (progress) {
      const { done, pending } = progress;
      totalChecklistFields = pending + done;
    }
  }
  const {
    overdue: childOverdue,
    completed: childCompleted,
    pending: childPending,
    owned: childOwned
  } = child;
  const childCount = childOverdue + childCompleted + childPending + childOwned;

  const {
    overdue: parentOverdue,
    completed: parentCompleted,
    pending: parentPending,
    owned: parentOwned
  } = parent;
  const parentCount =
    parentOverdue + parentCompleted + parentPending + parentOwned;

  const openFiles = useCallback(() => {
    if (isSrw) _showSrwDock("files");
    else _showDock("files");
  }, []);

  const openChecklist = useCallback(() => {
    if (isSrw) _showSrwDock("checklist");
    else _showDock("checklist");
  }, []);

  let privacyValue = "Public";
  if (privacy === "content") privacyValue = "Hide chat content";
  else if (privacy === "full") privacyValue = "Hide everything";

  return (
    <StyledDock open={activeDock}>
      <InfoSection title="Info" isSrw={isSrw} />
      <Content>
        <Context>
          {!isSrw && (
            <BreadcrumbWrapper>
              <BreadCrumbs roomId={room} />
            </BreadcrumbWrapper>
          )}
          <Workflow roomId={room} />
          <Title title={title} type={type} roomId={room} />
        </Context>
        <ItemList>
          <Type roomId={room} />
          <Item
            label="Owner"
            value={owner}
            owner={owner}
            icon="photo"
            handleClick={toggleBoolean}
          />
          <Item
            label="Participants"
            value={`${users.length}`}
            icon="group"
            location="chatroom-header"
            handleClick={_participantTrigger}
          />
          {totalChecklistFields > 0 && (
            <Item
              label="Checklist"
              value={`${totalChecklistFields}`}
              icon="tasklist"
              handleClick={openChecklist}
            />
          )}
          {!isSrw && (
            <Item
              label="Related Conversations"
              icon="related"
              value={`Parent: ${parentCount}, Child: ${childCount}`}
              handleClick={() => _toggleRelatedModal({ open: true })}
            />
          )}
          {!isSrw && (
            <Item label="Primary Parent" icon="related" value="parent" />
          )}
          {fileCount !== 0 && (
            <Item
              label="Files"
              icon="files"
              value={`${fileCount}`}
              handleClick={openFiles}
            />
          )}

          <Item
            label="Privacy Settings"
            icon="privacy"
            value={privacyValue}
            handleClick={() => _showDock("privacy")}
          />

          {!isSrw && <DueDate roomId={room} statusTrigger={statusTrigger} />}
          <Email roomId={room} />

          {templateId && <Reminders roomId={room} />}

          {!isSrw && (
            <Priority roomId={room} priorityTrigger={_setPriorityTrigger} />
          )}

          <Item
            label="Details"
            icon="info"
            value="Creator, Date Created, Age"
            handleClick={() => _showDock("details")}
          />

          {!isSrw && (
            <Item
              label="Objective"
              icon="objective"
              value={description || "No objective"}
              handleClick={() => _showDock("objective")}
            />
          )}
        </ItemList>
        {!isSrw && (
          <LeaveContainer>
            <div onClick={() => dispatch(removeRoomMember(currentUser, room))}>
              <Icon type="logOut" />
              Leave Conversation
            </div>
          </LeaveContainer>
        )}
      </Content>
    </StyledDock>
  );
};

Info.defaultProps = {
  templateId: null
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  membership: getChatroomMembership(app, props.room),
  users: app.roomMembers,
  uiTriggers: app.uiTriggers,
  title: getChatroomTitle(app, props.room),
  currentUser: getCurrentUserId(app),
  type: getChatroomType(app, props.room),
  owner: getChatroomOwner(app, props.room),
  chatroomChecklists: getChatroomChecklists(app, props.room),
  parent: app.chatRooms.relatedCount.parent,
  child: app.chatRooms.relatedCount.child,
  privacy: getChatroomPrivacy(app, props.room),
  description: getDescription(app, props.room),
  fileCount: getChatroomFileCount(app, props.room),
  ownerDropdown: app.uiTriggers.owner,
  templateId: getTemplateId(app, props.room)
});

export default connect(mapStateToProps, {
  _toggleDock: toggleDock,
  _showSrwDock: showSrwDock,
  _showDock: showDock,
  _toggleRelatedModal: toggleRelatedModal,
  _participantTrigger: toggleParticipants,
  _setPriorityTrigger: setPriorityTrigger,
  _setOwnerTriggerFromInfoPanel: setOwnerTriggerFromInfoPanel
})(Info);
