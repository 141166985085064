// @flow

import * as R from "ramda";
import { Set as ImmutuableSet } from "immutable";
import { toast } from "react-toastify";
import moment from "moment";

import {
  all,
  takeEvery,
  put,
  call,
  select,
  take,
  takeLatest
} from "redux-saga/effects";
import { convertStatusIdSetToArray } from "src/utils";

import {
  getWorkflowTemplates,
  getCustomStatuses,
  getFilteredChatRooms,
  getChatRoomsById,
  getCurrentFilterSortBy
} from "src/reducers";

import * as api from "src/api/chatroom";

import defaultTypes from "src/constants/conversationTypes";

import * as filterApi from "src/api/filter";
import getAppState, {
  getLastOrg,
  getUser,
  getSavedFilter,
  getFilter,
  getUserMembership
} from "src/selectors";
import * as atypes from "src/constants/actionTypes";
import { rsf } from "src/db";

import {
  filterByOwner,
  filterByWorkflow,
  filterByApproved,
  filterByType,
  filterByText,
  filterByParent,
  filterByMine,
  filterByUnread,
  filterByCompletion
} from "src/utils/filters";

import type { Action } from "src/types";

function* savePinnedList({ payload }: Action): any {
  try {
    const orgId = yield select(getLastOrg);
    const { uid } = yield select(getUser);
    const filter = yield select(getFilter);
    const savedFilters = yield select(getSavedFilter);
    const blacklistedNames = ["My Conversations"];
    const existingNames = R.map(filter => filter.name, savedFilters);

    if ([...blacklistedNames, ...existingNames].includes(payload.name)) {
      toast.error(`Filter ${payload.name} already exist`);
    } else {
      const filters = [
        { name: payload.name, filter: { ...filter, name: payload.name } },
        ...savedFilters
      ];

      const convertedFilter = convertStatusIdSetToArray(filters);

      yield call(api.savePinnedList, {
        filters: convertedFilter,
        orgId,
        uid
      });

      yield put({
        type: atypes.SAVE_PINNED_LIST_SUCCESS,
        payload: {
          name: payload.name
        }
      });

      toast.success(`Saved ${payload.name}`);
    }
  } catch (error) {
    toast.error(`Error in saving filter ${payload.name}`);

    yield put({
      type: atypes.SAVE_PINNED_LIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSavePinnedList(): any {
  yield takeEvery(atypes.SAVE_PINNED_LIST_REQUEST, savePinnedList);
}

function* editPinnedList({ payload }: Action): any {
  try {
    const { currentClickedSavedFilter } = (yield select(
      getAppState
    )).chatRooms.filters;
    const orgId = yield select(getLastOrg);
    const { uid } = yield select(getUser);
    const savedFilters = yield select(getSavedFilter);
    const existingNames = R.map(filter => filter.name, savedFilters);
    const blacklistedNames = ["My Conversations"];

    if ([...blacklistedNames, ...existingNames].includes(payload.name)) {
      throw new Error("Name already exists");
    }

    const saveNewName = savedFilter => {
      if (savedFilter.name === currentClickedSavedFilter) {
        return { ...savedFilter, name: payload.name };
      }
      return savedFilter;
    };

    const renamedFilters = R.map(saveNewName, savedFilters);

    // Converting Immutuable set to javasctipt array as firestore don't support Immutuable Set
    const filters = convertStatusIdSetToArray(renamedFilters);

    yield call(api.savePinnedList, {
      filters,
      orgId,
      uid
    });

    yield put({
      type: atypes.EDIT_PINNED_LIST_SUCCESS,
      payload: {
        name: payload.name
      }
    });

    yield put({
      type: atypes.HIDE_SAVE_PINNED_LIST,
      payload: {}
    });

    toast.success(`Saved ${payload.name}`);
  } catch (error) {
    toast.error(`Error in saving filter ${payload.name}`);

    yield put({
      type: atypes.EDIT_PINNED_LIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchEditPinnedList(): any {
  yield takeEvery(atypes.EDIT_PINNED_LIST_REQUEST, editPinnedList);
}

function* deletePinnedList({ payload }: Action): any {
  try {
    const orgId = yield select(getLastOrg);
    const { uid } = yield select(getUser);
    const savedFilters = yield select(getSavedFilter);

    // Converting Immutuable set to javasctipt array as firestore don't support Immutuable Set
    const convertedFilter = convertStatusIdSetToArray(savedFilters);

    yield call(api.savePinnedList, {
      filters: convertedFilter.filter(f => f.name !== payload.name),
      orgId,
      uid
    });
  } catch (error) {
    yield put({
      type: atypes.DELETE_PINNED_LIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchDeletePinnedList(): any {
  yield takeEvery(atypes.DELETE_PINNED_LIST_REQUEST, deletePinnedList);
}

function* syncPinnedList(): any {
  const orgId = yield select(getLastOrg);
  const { uid } = yield select(getUser);

  try {
    const channel = rsf.firestore.channel(
      `userData/${uid}/appData/settings/${orgId}`
    );

    while (true) {
      const snapshot = yield take(channel);

      for (const { doc } of snapshot.docChanges()) {
        const { filters, standard, custom } = doc.data();

        // Converting  back javasctipt array to immutuable set
        if (filters) {
          const convertedFilter = filters.map(item => {
            if (item.filter.customStatuses) {
              return {
                name: item.name,
                filter: {
                  ...item.filter,
                  customStatuses: ImmutuableSet(item.filter.customStatuses)
                }
              };
            }
            return item;
          });

          yield put({
            type: atypes.SYNC_PINNED_LIST_SUCCESS,
            payload: {
              filters: convertedFilter || []
            }
          });
        }

        if (standard) {
          yield put({
            type: atypes.SYNC_STANDARD_FILTER_LAST_READ_SUCCESS,
            payload: { ...standard }
          });
        }
        if (custom) {
          yield put({
            type: atypes.SYNC_CUSTOM_FILTER_LAST_READ_SUCCESS,
            payload: { ...custom }
          });
        }
      }
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_PINNED_LIST_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSyncPinnedList(): any {
  yield takeLatest(atypes.LOAD_CHATROOMS_SUCCESS, syncPinnedList);
}

function* updateStandardLastRead({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const orgId = yield select(getLastOrg);

    yield call(filterApi.setStandardLastRead, uid, orgId, payload);

    yield put({
      type: atypes.SET_STANDARD_FILTER_LAST_READ_SUCCESS,
      payload
    });
  } catch (error) {
    yield put({
      type: atypes.SET_STANDARD_FILTER_LAST_READ_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchUpdateStandardLastRead(): any {
  yield takeEvery(
    atypes.SET_STANDARD_FILTER_LAST_READ_REQUEST,
    updateStandardLastRead
  );
}

function* updateCustomLastUpdated({ payload }: Action): any {
  try {
    const customLastUpdated = {};
    const customLastRead = {};
    const { filters, chatrooms } = payload;
    const membership = yield select(getUserMembership);
    const app = yield select(getAppState);
    const currentUser = app.currentUser.uid;
    const { readMessageCount, userName: users } = app.chatRooms;
    const workflows = app.workflow.byId;
    const customStatuses = getCustomStatuses(app);

    const { uid } = app.currentUser;
    R.map(filterObj => {
      const { filter, name } = filterObj;

      const filteredRooms = R.filter(
        R.allPass([
          filterByMine(filter, membership),
          filterByUnread(filter, readMessageCount),
          filterByApproved(filter),
          filterByOwner(filter, currentUser),
          filterByWorkflow(filter),
          filterByParent(filter),
          filterByText(filter, workflows, users, currentUser),
          filterByType(filter),
          filterByCompletion(filter, customStatuses)
        ]),
        R.mergeAll(chatrooms)
      );
      R.map(room => {
        if (R.has(name, customLastUpdated)) {
          if (customLastUpdated[name].isBefore(moment(room.updatedAt))) {
            customLastUpdated[name] = moment(room.updatedAt);
          }
        } else {
          customLastUpdated[name] = moment(room.updatedAt);
        }
        if (uid === room.lastMessageAuthor) {
          customLastRead[name] = moment(Date.now());
        }
        return null;
      }, filteredRooms);
      return null;
    }, filters);

    if (!R.isEmpty(customLastRead)) {
      // optimistic update
      yield put({
        type: atypes.SET_CUSTOM_FILTER_LAST_READ_SUCCESS,
        payload: customLastRead
      });

      // update in firestore
      yield put({
        type: atypes.SET_CUSTOM_FILTER_LAST_READ_REQUEST,
        payload: customLastRead
      });
    }

    if (!R.isEmpty(customLastUpdated)) {
      yield put({
        type: atypes.SET_CUSTOM_FILTER_LAST_UPDATED_SUCCESS,
        payload: {
          ...customLastUpdated
        }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_CUSTOM_FILTER_LAST_UPDATED_FAILURE,
      payload: { error }
    });
  }
}

function* watchUpdateCustomLastUpdated(): any {
  yield takeEvery(
    atypes.SET_CUSTOM_FILTER_LAST_UPDATED_REQUEST,
    updateCustomLastUpdated
  );
}

function* watchForChatroomsAndPinnedListSuccess(): any {
  const actions = yield all([
    take(atypes.LOAD_CHATROOMS_SUCCESS),
    take(atypes.SYNC_PINNED_LIST_SUCCESS)
  ]);
  const chatrooms = actions[0].payload;

  const { filters } = actions[1].payload;

  yield put({
    type: atypes.SET_CUSTOM_FILTER_LAST_UPDATED_REQUEST,
    payload: { filters, chatrooms }
  });
}

function* updateCustomLastRead({ payload }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const orgId = yield select(getLastOrg);

    yield call(filterApi.setCustomLastRead, uid, orgId, payload);

    yield put({
      type: atypes.SET_CUSTOM_FILTER_LAST_READ_SUCCESS,
      payload
    });
  } catch (error) {
    yield put({
      type: atypes.SET_CUSTOM_FILTER_LAST_READ_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchUpdateCustomLastRead(): any {
  yield takeEvery(
    atypes.SET_CUSTOM_FILTER_LAST_READ_REQUEST,
    updateCustomLastRead
  );
}

function* searchConversationTypes({ payload }: Action) {
  try {
    const searchQuery = payload.text.toLowerCase();
    let workflows = getWorkflowTemplates(yield select(getAppState));
    if (R.isEmpty(workflows)) {
      yield take(atypes.SYNC_WORKFLOWS_SUCCESS);
      workflows = getWorkflowTemplates(yield select(getAppState));
    }
    const allProcessTypes = [
      ...defaultTypes,
      ...R.reject(
        workflow => R.toLower(workflow?.settings?.systemTitle || "") === "task",
        workflows
      )
    ];

    const processTypes = R.sortBy(R.compose(R.toLower, R.prop("title")));
    const searchedConversationTypes = R.map(
      c => c.id,
      processTypes(
        R.filter(
          conversationType =>
            R.includes(searchQuery, conversationType.title.toLowerCase()),
          allProcessTypes
        )
      )
    );

    yield put({
      type: atypes.GET_SEARCHED_CONVERSATION_TYPES,
      payload: {
        searchedConversationTypes
      }
    });
  } catch (e) {
    console.error("Unable to process the user defined process templates: ", e);
    yield put({
      type: atypes.GET_SEARCHED_CONVERSATION_TYPES_ERROR,
      payload: {
        e
      }
    });
  }
}

function* watchSearchConversationTypes(): any {
  yield takeEvery(atypes.SET_CONVERSATION_TYPE_SEARCH, searchConversationTypes);
}

function* searchPinnedLists() {
  try {
    const searchQuery = (yield select(getAppState)).header.setInputSearchQuery;
    const savedPinnedList = (yield select(getAppState)).chatRooms.filters.saved;

    const result = R.reduce(
      (pinnedLists, pinnedList) => {
        if (R.includes(searchQuery, pinnedList.name.toLowerCase())) {
          return [...pinnedLists, pinnedList];
        }
        return pinnedLists;
      },
      [],
      savedPinnedList
    );

    yield put({
      type: atypes.GET_SEARCHED_PINNED_LISTS,
      payload: {
        result
      }
    });
  } catch (e) {
    yield put({
      type: atypes.GET_SEARCHED_PINNED_LISTS_ERROR,
      payload: {
        e
      }
    });
  }
}

function* watchSearchPinnedLists(): any {
  yield takeEvery(atypes.SET_CONVERSATION_TYPE_SEARCH, searchPinnedLists);
}
function* watchPinListChange(): any {
  yield takeEvery(atypes.SYNC_PINNED_LIST_SUCCESS, searchPinnedLists);
}

function* searchQuickFilteredWorkflows() {
  try {
    const app = yield select(getAppState);
    const searchQuery = (yield select(
      getAppState
    )).header.setInputSearchQuery.toLowerCase();
    const quickFilteredWorkflows = app.workflow.allIds;
    let workflows = app.workflow.byId;
    if (R.isEmpty(workflows)) {
      yield take(atypes.SYNC_WORKFLOWS_SUCCESS);
      workflows = app.workflow.byId;
    }

    const updatedWorkflows = R.filter(
      id =>
        workflows[id] &&
        R.includes(searchQuery, workflows[id].title.toLowerCase()) &&
        !workflows[id].deleted,
      quickFilteredWorkflows
    );

    yield put({
      type: atypes.UPDATE_SEARCHED_QUICK_FILTER_WORKFLOWS,
      payload: { updatedWorkflows }
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchSearchQuickFilteredWorkflows(): any {
  yield takeEvery(
    atypes.SET_CONVERSATION_TYPE_SEARCH,
    searchQuickFilteredWorkflows
  );
}

function* getSuggestedWorkflows() {
  try {
    const { uid } = yield select(getUser);
    const orgId = yield select(getLastOrg);
    const workflowsById = (yield select(getAppState)).workflow.byId;

    const suggestedWorkflows = yield call(
      filterApi.getSuggestedWorkflows,
      uid,
      orgId
    ) || [];

    // Filter deleted processes from suggestedWorkflows
    const activeSuggestedWorkflows = suggestedWorkflows.filter(
      workflow => workflowsById[workflow] && !workflowsById[workflow].deleted
    );

    yield put({
      type: atypes.GET_SUGGESTED_WORKFLOW_SUCCESS,
      payload: { suggestedWorkflows: activeSuggestedWorkflows }
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.GET_SUGGESTED_WORKFLOW_FAILURE,
      payload: {
        e
      }
    });
  }
}

function* watchSyncWorkflow(): any {
  yield take(atypes.SYNC_WORKFLOWS_SUCCESS);
  yield call(getSuggestedWorkflows);
}

function* watchDeleteWorkflow(): any {
  yield takeEvery(atypes.DELETE_WORKFLOW_SUCCESS, getSuggestedWorkflows);
}

function* updateSuggestedWorkflows({ payload }: Action) {
  try {
    const { uid } = yield select(getUser);
    const orgId = yield select(getLastOrg);
    const { suggestedWorkflows } = (yield select(
      getAppState
    )).chatRooms.filters;

    yield call(filterApi.setSuggestedWorkflows, uid, orgId, suggestedWorkflows);

    yield put({
      type: atypes.UPDATE_SUGGESTED_WORKFLOWS_SUCCESS,
      payload: {}
    });
  } catch (e) {
    yield put({
      type: atypes.UPDATE_SUGGESTED_WORKFLOWS_FAILURE,
      payload: {
        suggestedWorkflows: R.filter(workflowId => workflowId !== payload.id)
      }
    });
  }
}

function* watchupdateSuggestedWorkflows(): any {
  yield takeLatest(
    atypes.UPDATE_SUGGESTED_WORKFLOWS_REQUEST,
    updateSuggestedWorkflows
  );
}
function* getSegmentedList() {
  try {
    const currentFilterSortBy =
      getCurrentFilterSortBy(yield select(getAppState)) || "";

    const isSortByStatus = R.startsWith("status", currentFilterSortBy);
    const isSortByPriority = R.startsWith("priority", currentFilterSortBy);

    if (isSortByStatus) {
      const roomsById = getChatRoomsById(yield select(getAppState));
      const filteredChatrooms = getFilteredChatRooms(yield select(getAppState));
      const filteredSortedRooms = filteredChatrooms
        ? // Sort by latest chatrooms
          R.sort((roomA, roomB) => {
            const roomAUpdatedAt = new Date(
              roomsById[roomA]?.updatedAt || 0
            ).getTime();
            const roomBUpdatedAt = new Date(
              roomsById[roomB]?.updatedAt || 0
            ).getTime();

            return roomBUpdatedAt - roomAUpdatedAt;
          }, filteredChatrooms.toJS())
        : null;

      const segmentedData = filteredSortedRooms
        ? R.groupBy(chatroom => `${roomsById[chatroom].status}`)(
            filteredSortedRooms
          )
        : {};

      yield put({
        type: atypes.UPDATE_SEGMENTED_LIST_BY_STATUS,
        payload: {
          segmentedStatus: segmentedData
        }
      });
    }

    if (isSortByPriority) {
      const roomsById = getChatRoomsById(yield select(getAppState));
      const filteredRoom = getFilteredChatRooms(yield select(getAppState));
      const filteredChatrooms = filteredRoom ? filteredRoom.toJS() : null;

      const segmentedData = filteredChatrooms
        ? R.groupBy(chatroom => `${roomsById[chatroom].priority}`)(
            filteredChatrooms
          )
        : {};

      yield put({
        type: atypes.UPDATE_SEGMENTED_LIST_BY_PRIORITY,
        payload: {
          segmentedStatus: segmentedData
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetSegmentedList(): any {
  yield all([
    takeEvery(atypes.BATCH_LOAD_ROOMS_SUCCESS, getSegmentedList),
    takeEvery(atypes.TRIGGER_FILTER_UPDATE, getSegmentedList),
    takeEvery(atypes.SET_SAVED_FILTER, getSegmentedList)
  ]);
}

export default [
  watchSavePinnedList(),
  watchSyncPinnedList(),
  watchPinListChange(),
  watchDeletePinnedList(),
  watchUpdateStandardLastRead(),
  watchUpdateCustomLastUpdated(),
  watchForChatroomsAndPinnedListSuccess(),
  watchUpdateCustomLastRead(),
  watchSearchConversationTypes(),
  watchSearchPinnedLists(),
  watchEditPinnedList(),
  watchSearchQuickFilteredWorkflows(),
  watchSyncWorkflow(),
  watchDeleteWorkflow(),
  watchupdateSuggestedWorkflows(),
  watchGetSegmentedList()
];
