// @flow

import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import TextArea from "react-autosize-textarea";

import { Display, NoTitle, Edit } from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import { setAttribute } from "src/actions/chatroom";
import { getChatroomTitle } from "src/reducers";
import type { RoomId, AppState } from "src/types";

type Props = {
  title: any,
  type: any,
  _setAttribute: Function,
  roomId: RoomId
};

const Title = ({ title, type, _setAttribute, roomId }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(title);

  useEffect(() => {
    setValue(title);
  }, [roomId]);

  const handleSave = useCallback(() => {
    if (value !== title)
      _setAttribute(roomId, {
        title: value
      });
    setEditMode(false);
  }, [value, _setAttribute, roomId, title]);

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        handleSave();
      }
    },
    [handleSave]
  );

  if (editMode === false) {
    return (
      <Display onClick={() => setEditMode(true)}>
        {value}
        {type === "workflow" && (value || "").length === 0 && (
          <NoTitle>No title</NoTitle>
        )}
      </Display>
    );
  }
  return (
    <OutsideClickHandler onClickOutside={handleSave}>
      <Edit>
        <TextArea
          value={value}
          autoFocus
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Edit>
    </OutsideClickHandler>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  title: getChatroomTitle(app, props.roomId)
});

export default connect(mapStateToProps, {
  _setAttribute: setAttribute
})(Title);
