// @flow

import React from "react";
import { connect } from "react-redux";

import Item from "./Item";
import { getChatroomPriority } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  priority: string,
  priorityTrigger: Function
};

const Priority = ({ priority, priorityTrigger }: Props) => (
  <Item
    label="Priority"
    icon={priority || "normal"}
    value={
      priority ? priority.charAt(0).toUpperCase() + priority.slice(1) : "Normal"
    }
    handleClick={priorityTrigger}
  />
);

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  priority: getChatroomPriority(app, props.roomId)
});

export default connect(mapStateToProps)(Priority);
