// @flow

import styled from "@emotion/styled";
import { Select as StyledSelect } from "src/styles/dropdown";

const Selection = styled.div`
  min-height: 23px;
  line-height: 23px;
`;

const Select = styled(StyledSelect)`
  > div:first-of-type {
    width: 100%;
  }
`;

export { Selection, Select };
