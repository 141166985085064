// @flow

import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import * as R from "ramda";

import { Skeleton } from "@chakra-ui/react";
import {
  File as StyledFile,
  Thumbnail,
  FileInfo,
  Name,
  Author,
  MenuContainer,
  Details,
  Size
} from "./styles";
import Dropdown from "./Dropdown";
import NameOrEmail from "src/containers/user/NameOrEmail";
import FileThumbnail from "./Checklist/File/FileThumbnail";
import Icon from "src/icons";
import { getFile } from "src/reducers";
import { getFile as resolveFile, getFileThumbnail } from "src/actions/file";
import { getFileSize } from "src/utils/file";
import useFileViewer from "src/hooks/useFileViewer";
import type { AppState, UnifizeFile, RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  name: string,
  thumbnail: ?Object,
  file: UnifizeFile,
  _getFile: Function,
  modal: boolean
};

const File = ({ roomId, name, file, thumbnail, _getFile, modal }: Props) => {
  const dispatch = useDispatch();

  const { openFile } = useFileViewer({ file, modal, roomId });

  const [fetching, setFetching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (!file && name) {
      _getFile(name);
      setFetching(true);
    }

    if (file) {
      setFetching(false);
    }
  }, [file, name]);

  useEffect(() => {
    if (
      (!thumbnail || moment().diff(thumbnail?.date, "minutes") > 10) &&
      name
    ) {
      dispatch(getFileThumbnail({ name, roomId }));
    }
  }, [file, roomId]);

  const showFileMenu = useCallback((e: any) => {
    e.stopPropagation();
    setShowDropdown(true);
  }, []);

  if (fetching) return <Skeleton height="70px" />;

  if (file) {
    const { versions } = file;
    const version = R.last(versions || []) || {};
    const { size, uploadTime, uploader } = version;
    let time = "";
    if (uploadTime && uploadTime) {
      time = moment(uploadTime).format("MMM DD, YYYY");
    }

    return (
      <StyledFile>
        <Thumbnail onClick={() => openFile()}>
          <FileThumbnail
            roomId={roomId}
            file={file}
            originalName={file.originalName}
            customWrapper
          />
        </Thumbnail>
        <FileInfo>
          <div>
            <Name>{file.originalName}</Name>
            <Size>{getFileSize(size)}</Size>
          </div>
          <Details>
            <Author>
              <NameOrEmail uid={uploader} />
            </Author>
            <div>{time || ""}</div>
          </Details>
        </FileInfo>
        <MenuContainer onClick={showFileMenu}>
          <Icon type="dockMenu" />
        </MenuContainer>
        {showDropdown ? (
          <Dropdown setShowDropdown={setShowDropdown} file={file} />
        ) : null}
      </StyledFile>
    );
  }

  return null;
};

const mapStateToProps = ({ app }: { app: AppState }, { name }) => ({
  file: getFile(app, name)
});

export default connect(mapStateToProps, {
  _getFile: resolveFile
})(File);
