// @flow

import React from "react";

import SearchItem from "./Item";
import { SearchResult } from "./styles";
import autoPosition from "src/components/autoPosition";

type Props = {
  focus: number,
  result: Array<{
    id: number,
    type: string,
    title: string,
    invocationCount: ?number
  }>,
  handleSelect: Function
};

const Result = ({ result, focus, handleSelect }: Props) => (
  <>
    {result.length > 0 ? (
      <SearchResult>
        <ul>
          {result.map((item, i) => (
            <SearchItem
              key={item.id}
              handleSelect={handleSelect}
              room={item}
              focus={focus === i}
            />
          ))}
        </ul>
      </SearchResult>
    ) : null}
  </>
);

export default autoPosition(Result, {
  bottomOffset: "36px",
  topOffset: "4px",
  position: "top",
  leftOffset: "-1px",
  rightOffset: "-3px"
});
